import TextOutputField from './text-output-field.ce.vue';
export default {
  props: {
    title: String,
    titleColor: String,
    coefficient: Number,
    exponent: Number,
    isMobile: Boolean,
    isHeat: Boolean
  },
  computed: {
    labelOutputPa() {
      return this.isHeat ? this.labelOutputPaHeat : this.labelOutputPaCool;
    },
    labelOutputPaSum() {
      return this.isHeat ? this.labelOutputPaHeatSum : this.labelOutputPaCoolSum;
    }
  },
  data() {
    return {
      resetValue: false,
      labelVL: "VL(°C)",
      labelRL: "RL (°C)",
      labelRT: "RT (°C)",
      labelConvection: "Konvektion + (x%)",
      labelOutputDelta: "ΔT (K)",
      labelOutputOmega: "Θ (K)",
      labelOutputPaHeat: "QHspez. (W/m²)",
      labelOutputPaHeatSum: "∑QHspez. (W/m²)",
      labelOutputPaCool: "QKspez. (W/m²)",
      labelOutputPaCoolSum: "∑QKspez. (W/m²)",
      vlValue: "",
      rlValue: "",
      rtValue: "",
      convectionValue: "",
      deltaResult: "",
      omegaResult: "",
      paResult: "",
      paSumResult: "",
      //mobile cards
      mobileCard: 'main-input-card-mobile',
      desktopCard: 'main-input-card',
      mainInputContainerMobile: "main-input-container-mobile",
      mainInputContainerStandard: "main-input-container",
      headerContainerStandard: "input-header-container",
      headerContainerMobile: "input-header-container-mobile"
    };
  },
  components: {
    'text-output-field': TextOutputField
  },
  methods: {
    updateFormula() {
      const vlNumeric = this.vlValue === "" ? 0 : parseFloat(this.vlValue);
      const rlNumeric = this.rlValue === "" ? 0 : parseFloat(this.rlValue);
      const rtNumeric = this.rtValue === "" ? 0 : parseFloat(this.rtValue);
      const convectionNumeric = this.convectionValue === "" ? 0 : parseFloat(this.convectionValue);
      if (!isNaN(vlNumeric) && !isNaN(rlNumeric)) {
        this.deltaResult = Math.abs(vlNumeric - rlNumeric);
        if (!isNaN(rtNumeric)) {
          this.omegaResult = Math.abs(rtNumeric - (vlNumeric + rlNumeric) / 2);
        } else {
          this.omegaResult = 'NaN';
        }
      } else {
        this.deltaResult = 'NaN';
      }
      if (this.omegaResult != 'NaN' && this.omegaResult != '') {
        this.paResult = parseFloat(this.coefficient * Math.pow(this.omegaResult, parseFloat(this.exponent))).toFixed(2);
        if (!isNaN(convectionNumeric)) {
          this.paSumResult = parseFloat(parseFloat(this.paResult) + parseFloat(this.paResult) * convectionNumeric / 100).toFixed(2);
        } else {
          this.paSumResult = 'NaN';
        }
      } else {
        this.paResult = 'NaN';
        this.paSumResult = 'NaN';
      }
      this.$refs.childComponent1.updateValue(this.deltaResult);
      this.$refs.childComponent2.updateValue(this.omegaResult);
      this.$refs.childComponent3.updateValue(this.paResult);
      this.$refs.childComponent4.updateValue(this.paSumResult);
    },
    vlValueChangeEvent(newVlValue) {
      this.vlValue = newVlValue;
      this.updateFormula();
    },
    rlValueChangeEvent(newRlValue) {
      this.rlValue = newRlValue;
      this.updateFormula();
    },
    rtValueChangeEvent(newRtValue) {
      this.rtValue = newRtValue;
      this.updateFormula();
    },
    convectionValueChangeEvent(newConvValue) {
      this.convectionValue = newConvValue;
      this.updateFormula();
    },
    resetValues() {
      this.$refs.childComponent1.resetValues();
      this.$refs.childComponent2.resetValues();
      this.$refs.childComponent3.resetValues();
      this.$refs.childComponent4.resetValues();
    }
  }
};