export default {
  emits: ["send-changed-value"],
  props: {
    outputValue: String,
    labelText: String,
    outputLabelText: String,
    isMobile: Boolean
  },
  data() {
    return {
      inputValue: '',
      outputValueData: '',
      textFieldMobile: 'text-field-mobile',
      textFieldStandard: 'text-field'
    };
  },
  computed: {
    isLabelActive() {
      return !!this.inputText;
    }
  },
  methods: {
    sendChangedValue() {
      this.$emit("send-changed-value", this.inputValue);
    },
    resetValues() {
      this.inputValue = "";
      this.outputValueData = "";
    },
    updateValue(newValue) {
      this.outputValueData = newValue;
    }
  }
};