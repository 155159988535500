import InputComponent from './input-component.ce.vue';
export default {
  components: {
    'input-component': InputComponent
  },
  props: {
    exponentHeat: Number,
    coefficientHeat: Number,
    exponentCold: Number,
    coefficientCold: Number
  },
  data() {
    return {
      titleHeating: 'Heizleistung',
      titleCooling: 'Kühlleistung',
      colorWarm: "#ef7961",
      colorCool: "#005092",
      isMobileScreen: false,
      mobileClass: "input-container-mobile",
      desktopClass: "input-container-desktop"
    };
  },
  mounted() {
    this.setMobileScreen();
    window.addEventListener('resize', this.setMobileScreen);
  },
  methods: {
    setMobileScreen() {
      // Check for table screen width
      this.isMobileScreen = window.innerWidth < 768;
    }
  }
};