import { defineCustomElement as VueDefineCustomElement, createApp, getCurrentInstance } from 'vue';
import App from './components/main-frame.ce.vue';
const defineCustomElement = (component, {
  plugins = []
} = {}) => VueDefineCustomElement({
  ...component,
  setup(...args) {
    const app = createApp({});
    plugins.forEach(app.use);
    const instance = getCurrentInstance();
    Object.assign(instance.appContext, app._context);
    Object.assign(instance.provides, app._context.provides);
    return component.setup?.(...args);
  }
});
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
const vuetify = createVuetify({
  components,
  directives
});
const customVuetifyElement = defineCustomElement(App, {
  plugins: [vuetify]
});
customElements.define('temp-rechner', customVuetifyElement);